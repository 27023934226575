import * as React from 'react'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsBudgetSitesTable } from '../../../Utils/TableUtils/headCells'
import { Tooltip } from '@mui/material'

function BudgetSitesTable(props) {
    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        rowCount={props.rows.length}
                        headCells={headCellsBudgetSitesTable}
                    />
                    <TableBody>
                        {props.rows.map((row, index) => {
                            if (row.type !== "site data") {
                                return (
                                    <TableRow key={`sites-${index}`}>
                                        <Tooltip title={row.type} enterTouchDelay={0}>
                                            <StyledTableCell>{row.type}</StyledTableCell>
                                        </Tooltip>
                                        <StyledTableCell>{row.data1 ? new Intl.NumberFormat('fr-FR').format(row.data1) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data2 ? new Intl.NumberFormat('fr-FR').format(row.data2) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data3 ? new Intl.NumberFormat('fr-FR').format(row.data3) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data4 ? new Intl.NumberFormat('fr-FR').format(row.data4) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data5 ? new Intl.NumberFormat('fr-FR').format(row.data5) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data6 ? new Intl.NumberFormat('fr-FR').format(row.data6) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data7 ? new Intl.NumberFormat('fr-FR').format(row.data7) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data8 ? new Intl.NumberFormat('fr-FR').format(row.data8) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data9 ? new Intl.NumberFormat('fr-FR').format(row.data9) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data10 ? new Intl.NumberFormat('fr-FR').format(row.data10) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data11 ? new Intl.NumberFormat('fr-FR').format(row.data11) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.data12 ? new Intl.NumberFormat('fr-FR').format(row.data12) : ""}</StyledTableCell>
                                        <StyledTableCell>{row.dataTotal ? new Intl.NumberFormat('fr-FR').format(row.dataTotal) : ""}</StyledTableCell>
                                        <StyledTableCell sx={{ color: ((row.dataTotal - row.ecartTotal) > 0) ? "#FF0000" : "#48AB4D" }}>{(row.ecartTotal && (row.dataTotal - row.ecartTotal !== 0)) ? new Intl.NumberFormat('fr-FR', { style: 'percent' }).format((row.dataTotal - row.ecartTotal) / row.ecartTotal) : ""}</StyledTableCell>
                                    </TableRow>
                                )
                            }
                            return null
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    )
}

export default BudgetSitesTable;
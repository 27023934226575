import Button from '../Button'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

import DashboardIcon from '../../img/icons/dashboard.svg'
import ResourcesIcon from '../../img/icons/resources.svg'
import SitesIcon from '../../img/icons/sites.svg'
import AppelsdOffreIcon from '../../img/icons/appeldoffre.svg'
import ContractsIcon from '../../img/icons/contracts.svg'
import ConsoIcon from '../../img/icons/conso.svg'
import VerifyIcon from '../../img/icons/verifyinvoices.svg'
import GreenIcon from '../../img/icons/greenenergy.svg'
import ProfilIcon from '../../img/icons/profil.svg'
import TeamIcon from '../../img/icons/team.svg'
import MandatesIcon from '../../img/icons/mandates.svg'
import SepaIcon from '../../img/icons/sepa.svg'
import InvoiceIcon from '../../img/icons/invoices.svg'
import BudgetIcon from '../../img/icons/budget.svg'
import TurpeIcon from '../../img/icons/turpe.png'

import EnergieVerte from '../../img/energie_verte.png'
import LogoutIcon from '../../img/icons/logout.svg'

import ArrowUp from '../../img/icons/arrow-up.svg'
import ArrowDown from '../../img/icons/arrow-down.svg'
import TopBar from './TopBar'

const apiUrl = process.env.REACT_APP_API_URL

function SideBar(props) {
    const navigate = useNavigate()
    const [showGaz, setShowGaz] = useState(props.openGaz)
    const [showElec, setShowElec] = useState(props.openElec)
    const [showAccount, setShowAccount] = useState(props.openAccount)

    const [isClient, setIsclient] = useState(false)

    useEffect(() => {
        checkIsClient()
    }, [])

    const checkIsClient = async () => {
        axios.get(`${apiUrl}/auth/info`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                setIsclient(response.data.isClient)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const handleLogout = async () => {
        try {
            await axios.post(
                `${apiUrl}/auth/logout`,
                "",
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
            navigate('/')
        }
        catch (error) {
            if (error && error.response.status && error.response.status === 401) {
                window.location.reload()
            }
        }
    }

    return (
        <>
            <TopBar isClient={isClient} />
            <div className="sidebar--container">
                <Button
                    cta="Ajouter un nouveau site"
                    additionnalClassName="sidebar--addbutton"
                    link='/add-sites-1?type=elec'
                    withPlus />
                <ul>
                    <Link to="/dashboard"><li className="sidebar--listelement">
                        <img
                            src={DashboardIcon}
                            alt="Dashboard Icon"
                            className="sidebar--listicon"
                        />
                        <span className={props.active === "Dashboard" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>
                            Tableau de bord</span>
                    </li></Link>
                    <Link to="/ressources"><li className="sidebar--listelement">
                        <img src={ResourcesIcon} alt="Ressources Icon" className="sidebar--listicon" />
                        <span className={props.active === "Ressources" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Ressources</span>
                    </li></Link>
                </ul>
                <div className="sidebar--listtitle__container"
                    onClick={() => {
                        setShowGaz(!showGaz)
                        setShowAccount(false)
                        setShowElec(false)
                    }}>
                    <span className="sidebar--listtitle">GAZ</span>
                    <img
                        src={showGaz ? ArrowUp : ArrowDown}
                        alt="Plier/déplier la catégorie"
                        className="sidebar--dropdownicon"
                    />
                </div>
                {showGaz &&
                    <ul>
                        <Link to="/gaz-sites"><li className="sidebar--listelement">
                            <img src={SitesIcon} alt="Sites gaz Icon" className="sidebar--listicon" />
                            <span className={props.active === "Sites gaz" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes sites</span>
                        </li></Link>
                        <Link to="/appels-offre-gaz"><li className="sidebar--listelement">
                            <img src={AppelsdOffreIcon} alt="Appel d'offres gaz Icon" className="sidebar--listicon" />
                            <span className={props.active === "Appels d'offres gaz" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes appels d'offres</span>
                        </li></Link>
                        <Link to="/contrats-gaz"><li className="sidebar--listelement">
                            <img src={ContractsIcon} alt="Contrats gaz Icon" className="sidebar--listicon" />
                            <span className={props.active === "Contrats gaz" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes contrats</span>
                        </li></Link>
                    </ul>
                }
                <div className="sidebar--listtitle__container"
                    onClick={() => {
                        setShowElec(!showElec)
                        setShowGaz(false)
                        setShowAccount(false)
                    }}>
                    <span className="sidebar--listtitle">ÉLECTRICITÉ</span>
                    <img
                        src={showElec ? ArrowUp : ArrowDown}
                        alt="Plier/déplier la catégorie"
                        className="sidebar--dropdownicon"
                    />
                </div>
                {showElec &&
                    <ul>
                        <Link to="/elec-sites"><li className="sidebar--listelement">
                            <img src={SitesIcon} alt="Sites elec Icon" className="sidebar--listicon" />
                            <span className={props.active === "Sites elec" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes sites</span>
                        </li></Link>
                        <Link to="/appels-offre-elec"><li className="sidebar--listelement">
                            <img src={AppelsdOffreIcon} alt="Appel d'offres elec Icon" className="sidebar--listicon" />
                            <span className={props.active === "Appels d'offres elec" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes appels d'offres</span>
                        </li></Link>
                        <Link to="/contrats-elec"><li className="sidebar--listelement">
                            <img src={ContractsIcon} alt="Contrats elec Icon" className="sidebar--listicon" />
                            <span className={props.active === "Contrats elec" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes contrats</span>
                        </li></Link>
                        <Link to="/budget">
                            <li className="sidebar--listelement">
                                <img src={BudgetIcon} alt="Budget Icon" className="sidebar--listicon" />
                                <span className={props.active === "Budget" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mon budget</span>
                            </li>
                        </Link>
                        <Link to="/conso"><li className="sidebar--listelement">
                            <img src={ConsoIcon} alt="Conso Icon" className="sidebar--listicon" />
                            <span className={props.active === "Conso" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes consommations</span>
                        </li></Link>
                        <Link to="/invoice-verification"><li className="sidebar--listelement">
                            <img src={VerifyIcon} alt="Factures Icon" className="sidebar--listicon" />
                            <span
                                className={props.active === "Verifier mes factures" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}
                            >Vérifier mes factures</span>
                        </li></Link>
                        <Link to="/green-energy"><li className="sidebar--listelement">
                            <img src={GreenIcon} alt="Electricite verte Icon" className="sidebar--listicon" />
                            <span
                                className={props.active === "Electricite verte" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>
                                Mon électricité verte</span>
                        </li></Link>
                        <Link to="/optimisation-turpe"><li className="sidebar--listelement">
                            <img src={TurpeIcon} alt="TURPE Icon" className="sidebar--listicon" />
                            <span
                                className={props.active === "TURPE" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>
                                Optimisation TURPE</span>
                        </li></Link>
                    </ul>
                }
                <div className="sidebar--listtitle__container"
                    onClick={() => {
                        setShowAccount(!showAccount)
                        setShowGaz(false)
                        setShowElec(false)
                    }}>
                    <span className="sidebar--listtitle">COMPTE</span>
                    <img
                        src={showAccount ? ArrowUp : ArrowDown}
                        alt="Plier/déplier la catégorie"
                        className="sidebar--dropdownicon"
                    />
                </div>
                {showAccount &&
                    <ul>
                        <Link to="/account"><li className="sidebar--listelement">
                            <img src={ProfilIcon} alt="Profil Icon" className="sidebar--listicon" />
                            <span
                                className={props.active === "Update" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}
                            >Mon profil</span>
                        </li></Link>
                        {isClient && <Link to="/contributors"><li className="sidebar--listelement">
                            <img src={TeamIcon} alt="Contributors Icon" className="sidebar--listicon" />
                            <span
                                className={props.active === "Contributors" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}
                            >Mes collaborateurs</span>
                        </li></Link>}
                        <Link to="/mandates"><li className="sidebar--listelement">
                            <img src={MandatesIcon} alt="Mandates Icon" className="sidebar--listicon" />
                            <span className={props.active === "Mandates" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes mandats</span>
                        </li></Link>
                        <Link to="/sepa"><li className="sidebar--listelement">
                            <img src={SepaIcon} alt="Sepa Icon" className="sidebar--listicon" />
                            <span className={props.active === "Sepa" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Mes mandats SEPA</span>
                        </li></Link>
                        <Link to="/wattvalue-invoices"><li className="sidebar--listelement">
                            <img src={InvoiceIcon} alt="Billing Icon" className="sidebar--listicon" />
                            <span className={props.active === "Billing" ? "sidebar--elementtitle sidebar--elementtitle__active" : "sidebar--elementtitle"}>Facturation</span>
                        </li></Link>
                    </ul>
                }
                <Link to="/green-energy"><img src={EnergieVerte}
                    alt="Venez découvrir notre offre d'énergie verte"
                    className="sidebar--newenergybutton"
                /></Link>
                <div className="sidebar--logout" onClick={() => handleLogout()}>
                    <img src={LogoutIcon} alt="Logout" className="sidebar--listicon" />
                    Déconnexion
                </div>
            </div>
        </>
    )
}

export default SideBar;